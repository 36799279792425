import { useCallback, useContext } from 'react';
import { useMutation } from 'react-query';
import { notification } from 'antd';

import { postApi } from 'api/crud';

import { StoreContext } from './useStore';
import types from './types';

export const useAuth = () => {
  const { dispatch, state } = useContext(StoreContext);

  const setUser = useCallback((data) => {
    dispatch({
      type: types.SET_USER,
      payload: data,
    });
  }, []);

  // useQuery(
  //   'users/me',
  //   async () => {
  //     const user = await getCurrentUserApi();
  //     setUser(user);
  //     return user;
  //   },
  //   {
  //     enabled: state.isAuthenticated && isEmpty(state.user),
  //   },
  // );

  const logout = useCallback(() => {
    dispatch({
      type: types.LOGOUT,
    });
  }, []);

  const loginMutation = useMutation(
    (payload) => postApi('auth/login', payload),
    {
      onSuccess: (res) => {
        dispatch({
          type: types.LOGIN,
          payload: res,
        });
      },
      onError: (error) => {
        notification.info({
          message: error?.message || 'Something wrong!',
        });
      },
      // onSettled: () => {
      //   queryClient.invalidateQueries('auth/login');
      // },
    },
  );

  const login = async (data) => {
    try {
      const res = await loginMutation.mutateAsync(data);
      return res;
    } catch (error) {
      // console.log(error, 'error');
      return null;
    }
  };

  return {
    user: state.user,
    logout,
    setUser,
    login,
    isAuthenticated: state.isAuthenticated,
  };
};

export const useHasRole = () => {
  const { state } = useContext(StoreContext);

  return state.isAuthenticated;
};
