import { UserOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Menu } from 'antd';
import { useAuth } from 'hooks/useAuth';

const RightContentHeader = () => {
  const { user, logout } = useAuth();
  const menu = (
    <Menu>
      <Menu.Item onClick={logout} key="logout">
        Logout
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={menu}>
      <div>
        {user?.name}{' '}
        <Avatar size="large" src={user?.avatar} icon={<UserOutlined />} />
      </div>
    </Dropdown>
  );
};

export default RightContentHeader;
