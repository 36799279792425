import { Alert, ConfigProvider, Empty } from 'antd';
import i18n from 'i18n';
import { ThemeProvider } from 'styled-components';
import { I18nextProvider } from 'react-i18next';
import theme from 'configs/theme.json';
import Routes from 'routes';
import { StoreContext, useStore } from 'hooks/useStore';
import ReactQueryProvider from 'ReactQueryProvider';
import { formConfig } from 'configs/config';
import GlobalStyle from 'GlobalStyle';
import 'antd/dist/antd.less';
import enUS from 'antd/lib/locale/en_US';
import OverwriteAntdStyle from 'OverwriteAntdStyle';
import logo from './logo.svg';

function App() {
  const [state, dispatch] = useStore();

  return (
    <StoreContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      <Alert.ErrorBoundary>
        <I18nextProvider i18n={i18n}>
          <ThemeProvider theme={theme}>
            <ConfigProvider
              locale={enUS}
              form={formConfig}
              renderEmpty={() => <Empty />}
            >
              <OverwriteAntdStyle />
              <GlobalStyle />
              <ReactQueryProvider>
                <Routes />
              </ReactQueryProvider>
            </ConfigProvider>
          </ThemeProvider>
        </I18nextProvider>
      </Alert.ErrorBoundary>
    </StoreContext.Provider>
  );
}

export default App;
