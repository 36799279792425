import produce from 'immer';
import { createContext, useReducer } from 'react';
import types from './types';

const initialState = {
  user: {},
  isAuthenticated: !!localStorage.getItem('sessionToken'),
  configs: null,
};

function reducer(state, action) {
  switch (action.type) {
    case types.SET_USER:
      state.user = action.payload;
      break;
    case types.LOGIN:
      state.isAuthenticated = true;
      state.user = action.payload;
      action.payload?.token && localStorage.setItem('sessionToken', action.payload?.token);
      break;
    case types.LOGOUT:
      localStorage.removeItem('sessionToken');
      window.location.href = '/login';
      break;
    default:
      throw new Error();
  }
}

export const useStore = () => {
  const [state, dispatch] = useReducer(produce(reducer), initialState);
  return [state, dispatch];
};

export const StoreContext = createContext({
  state: {},
  dispatch: () => {},
});
