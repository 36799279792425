import { MenuDataItem } from '@ant-design/pro-layout';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const MenuSidebarItem = ({ path }, dom) => <Link to={path ?? '/'}>{dom}</Link>;

MenuSidebarItem.propTypes = {
  path: PropTypes.string,
};

export default MenuSidebarItem;
