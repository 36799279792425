import { createGlobalStyle } from 'styled-components';
import { CLASS_UTILITY } from 'utils/css';

export default createGlobalStyle`
${CLASS_UTILITY}
.ant-design-pro {
  min-height: 100vh;
}
  .ant-image-preview-mask {
  background-color: rgba(0, 0, 0, 0.9) !important;
  }

  .ellipsis-t {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .section-wrapper {
    padding: 15px;
    background: #fff;
    border-radius: 8px;
  }
`;
