import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import { flatMap, groupBy, map } from 'lodash';
import PrivateLayoutPage from 'layouts/PrivateLayout';
import loadable from 'utils/loadable';
import { useContext } from 'react';
import { StoreContext } from 'hooks/useStore';

const routes = [
  // {
  //   path: '/',
  //   component: loadable(import('pages/Home')),
  //   exact: true,
  //   title: 'dashboard.title',
  // },
  {
    path: '/customilies',
    routes: [
      {
        path: '/',
        component: loadable(import('pages/customilies/List')),
        exact: true,
      },
    ],
  },
  {
    path: '/teeinblues',
    routes: [
      {
        path: '/',
        component: loadable(import('pages/teeinblues/List')),
        exact: true,
      },
    ],
  },
];

const groupRoutes = groupBy(
  flatMap(routes, (route) => {
    if (route.routes) {
      return map(route.routes, (subRoute) => ({
        ...subRoute,
        path: route.path + subRoute.path,
        exact: subRoute.path === '/',
        component: subRoute.component || route.component,
      }));
    }
    return route;
  }),
  'hasPrivateLayoutWrapper',
);

const wrappedRoutes = map(groupRoutes.undefined, (route) => (
  <PrivateRoute {...route} key={route.path} />
));

function PrivateRoute({ title, roles, ...rest }) {

  const { state } = useContext(StoreContext);

  return state.isAuthenticated ? (
    <Route {...rest} />
  ) : (
    <Redirect
      to={{
        pathname: '/login',
      }}
    />
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.any,
  title: PropTypes.string,
  hasPrivateLayoutWrapper: PropTypes.bool,
  roles: PropTypes.array,
};

const PrivateRoutes = () => (
  <PrivateLayoutPage>
    <Switch>
      <Redirect from="/" to="/customilies" exact />
      {wrappedRoutes}
    </Switch>
  </PrivateLayoutPage>
);

// export const privateRouteWithoutLayout = () =>
//   groupRoutes.false?.map((e) => <Route {...e} key={e.path} />);

export default PrivateRoutes;
