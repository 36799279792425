import {
  StarOutlined, UserOutlined,
} from '@ant-design/icons';
import ProLayout, { SettingDrawer } from '@ant-design/pro-layout';
import { Alert } from 'antd';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { appConfig } from 'configs/config';
import { useTranslation } from 'react-i18next';
import MenuHeader from './MenuHeader';
import MenuSidebarItem from './MenuSidebarItem';
import RightContentHeader from './RightContentHeader';

const getCurrentTab = (str) => {
  const paths = str && str.split('/');
  return paths && paths[1];
};

const PrivateLayout = ({ children }) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const [settings, setSetting] = useState({
    colorWeak: false,
    title: appConfig.title,
    headerHeight: 60,
    fixedHeader: true,
    fixSiderbar: true,
    language: 'en-US',
  });

  const ROUTES = useMemo(
    () => ({
      routes: [
        // {
        //   path: '/',
        //   name: 'Dashboard',
        //   icon: <DashboardFilled />,
        // },

        {
          key: 'customilies',
          path: '/customilies',
          name: t('customilies.title'),
          icon: <StarOutlined />,
        },
        {
          key: 'teeinblues',
          path: '/teeinblues',
          name: t('teeinblues.title'),
          icon: <UserOutlined />,
        },
      ],
    }),
    [t],
  );

  const selectedKey = getCurrentTab(pathname);

  return (
    <div className="h-screen">
      <ProLayout
        route={ROUTES}
        logo={appConfig.logo}
        location={{
          pathname,
        }}
        menuHeaderRender={MenuHeader}
        menuItemRender={MenuSidebarItem}
        rightContentRender={() => <RightContentHeader />}
        {...settings}
        language="en-US"
        selectedKeys={[selectedKey || 'customilies']}
      >
        <Alert.ErrorBoundary>{children}</Alert.ErrorBoundary>
      </ProLayout>
      <SettingDrawer
        pathname={pathname}
        settings={settings}
        onSettingChange={setSetting}
        disableUrlParams
        hideHintAlert
        hideCopyButton
        language="en-US"
      />
    </div>
  );
};

PrivateLayout.propTypes = {
  logout: PropTypes.func,
  userInfo: PropTypes.object,
};

export default PrivateLayout;
