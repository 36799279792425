import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
.ant-page-header {
  padding: 16px 0px;
}
.ant-list {
  .ant-col {
    height: 100%;
    .ant-list-item {
      height: 100%;
    }
    .ant-card {
      height: 100%;
      display: flex;
      flex-direction: column;
      .ant-card-body{
        flex:1;
      }
    }
  }
}
.ant-tabs-card {
  .ant-tabs-tab-active {
    background-color: ${({ theme }) => theme.palette.primary} !important;
    .ant-tabs-tab-btn {
      color: white !important;
    }
  }
}
`;
